import type { ImplementedCopilotFeatures, CopilotSettingsStore } from 'owa-copilot-settings-store';
import { getApplicationSettings } from 'owa-application-settings';
import checkLanguage from './checkLanguage';
import isCopilotFeatureEnabled from './isCopilotFeatureEnabled';
import { createStoreCheckForGenericEnablement } from './createStoreCheckForGenericEnablement';
import type { MailboxInfo } from 'owa-client-types';

type ElaborateScenarios = Extract<ImplementedCopilotFeatures, 'Elaborate'>;
const isCalendarElaborateEnabled = createStoreCheckForGenericEnablement<
    ElaborateScenarios,
    boolean
>('CalendarElaborate', isCalendarElaborateEnabledInternal);

export default isCalendarElaborateEnabled;

function isCalendarElaborateEnabledInternal(
    store: CopilotSettingsStore,
    language: string,
    scenario: ElaborateScenarios,
    mailboxInfo: MailboxInfo
): boolean {
    if (store.isEnabled) {
        const featureEnabled = store.featureInfo.get(scenario);
        if (featureEnabled?.isEnabled) {
            const appSettings = getApplicationSettings('Copilot', mailboxInfo);
            return (
                !!isCopilotFeatureEnabled(
                    scenario,
                    mailboxInfo,
                    appSettings.enableMultiLanguage /* skipLanguageCheck */
                ) &&
                checkLanguage(language, ['en']) && // only enabled for english currently
                appSettings.calendarElaborateEnabled && // This is controlled through the Killswitch ECS here: https://ecs.skype.com/Substrate/OutlookWebClient/configurations/1092852
                !!featureEnabled.capabilities &&
                'calendarElaborate' in featureEnabled.capabilities &&
                featureEnabled.capabilities.calendarElaborate
            );
        }
    }
    return false;
}
