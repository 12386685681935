import type { MailboxInfo } from 'owa-client-types';
import {
    copilotSettingsStore,
    lazyFetchCopilotSettings,
    FetchState,
    type ImplementedCopilotFeatures,
    type CopilotSettingsStore,
} from 'owa-copilot-settings-store';
import { logUsage } from 'owa-analytics';
import { getCurrentLanguage } from 'owa-localize';

type GenericEnablementCheckFunction<T extends ImplementedCopilotFeatures, K> = (
    store: CopilotSettingsStore,
    language: string,
    scenario: T,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean
) => K;

export function createStoreCheckForGenericEnablement<T extends ImplementedCopilotFeatures, K>(
    funcName: string,
    checkFunc: GenericEnablementCheckFunction<T, K>
): (scenario: T, mailboxInfo: MailboxInfo, skipLanguageCheck?: boolean) => K | undefined {
    return (scenario: T, mailboxInfo: MailboxInfo, skipLanguageCheck?: boolean) => {
        const language = getCurrentLanguage();
        const store = copilotSettingsStore(mailboxInfo);
        switch (store.fetchState) {
            case FetchState.NOT_STARTED:
                lazyFetchCopilotSettings.importAndExecute(mailboxInfo);
            /* falls through */
            case FetchState.IN_PROGRESS:
                /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
                 * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
                 *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
                logUsage(funcName + '_CheckedBeforeStorePopulated', {
                    scenario_1: scenario,
                    state_2: store.fetchState,
                });
                return undefined; //Need a separate undefined state when service call is in progress or not started so that perf optimizations can be made in the calling code
            case FetchState.SUCCEEDED:
            case FetchState.FAILED:
                return checkFunc(store, language, scenario, mailboxInfo, skipLanguageCheck);
        }
    };
}
