import type { DataCollector } from 'diagnostics-data-collection';
import { lazyRegisterDataCollectorIfNotRegistered } from 'diagnostics-data-collection';
import loc from 'owa-localize';
import { copilotSettingsDiagDataCollectorDescription } from './registerDiagDataCollector.locstring.json';
import { getAllStoreDataForDiagnostics } from '../store/store';
import { isFeatureEnabled } from 'owa-feature-flags';

export default async function registerDiagDataCollector() {
    const summaryDataCollector: DataCollector = {
        dataCollectionAction: summaryDataCollectionAction,
        name: 'OutlookCopilotSettingsStore',
        description: loc(copilotSettingsDiagDataCollectorDescription),
    };

    await lazyRegisterDataCollectorIfNotRegistered.importAndExecute(summaryDataCollector);
}

function summaryDataCollectionAction(): Promise<string> {
    const diagInfo = {
        storeData: getAllStoreDataForDiagnostics(),
        threadSummarizationEnablementData: {
            itemViewEnabled: isFeatureEnabled('rp-thread-summarization-item-view'),
        },
    };
    return Promise.resolve(JSON.stringify(diagInfo));
}
