export const SALES_COPILOT_METAOS_ADDIN_IDS = {
    titleId: 'P_280ba0c3-7951-e7a8-68d3-8d65f9ca8149',
    manifestId: 'c92c289e-ceb4-4755-819d-0d1dffdab6fa',
};

export const SALES_COPILOT_PROD_XML_ADDIN_IDS = {
    titleId: 'P_dc624f06-00fa-9bcc-c473-0208602df2d1',
    manifestId: 'c3b456a3-a41a-4ed4-8040-354f73574021',
};

export const SALES_COPILOT_DAILY_XML_ADDIN_IDS = {
    titleId: 'U_bebc48f9-c491-0f56-3c9d-5b5ab6c56a6c',
    manifestId: '38191e50-9910-4ea1-a645-7955e4114e77',
};

export const SALES_COPILOT_FRE_XML_ADDIN_IDS = {
    manifestId: '21966839-ab77-4ee6-8f52-16e6a1102923',
    titleId: 'U_19891fea-b47b-a39f-b239-09e0d9415ae0',
};

export const OUTLOOK_ADDIN_TITLE_ID = 'Outlook';
export const OUTLOOK_ADDIN_MANIFEST_ID = 'Outlook';
