// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/Scenario.cs
export enum Scenario {
    SuggestedDrafts = 0,
    Elaborate = 1,
    Coach = 2,
    ThreadSummarization = 3,
    InboxCatchup = 4,
    Chat = 5,
    ThemeVisualization = 6,
    ReadingVisualization = 7,
    MeetingPrepSummary = 8,
    FindMeetingTimesWithPeopleSearch = 9,
    PlaceSuggestions = 10,
    ReplyByMeeting = 11,
    ExplainPlacesReport = 12,
    Rewrite = 13,
    CoachApply = 16,
}

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/CopilotLicense.cs
export enum LicenseType {
    M365Copilot = 0,
    SalesCopilot = 1,
    TeamsCopilot = 2,
    BizChat = 3,
}

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/SupportedScenarios/Enums/ElaborateCapabilitiesEnum.cs
export enum ElaborateCapabilitiesType {
    TonePersonalization = 0,
    CalendarElaborate = 1,
}

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/SupportedScenarios/Enums/RewriteCapabilitiesEnum.cs
export enum RewriteCapabilitiesType {
    HighlightAndRewrite = 0,
    CalendarRewrite = 1,
}

interface SupportedScenarioBase {
    scenario: Scenario;
    supportedLanguages: string[];
}

interface GeneralScenario extends SupportedScenarioBase {
    scenario: Exclude<Scenario, Scenario.Elaborate | Scenario.Rewrite>;
}

interface ElaborateScenario extends SupportedScenarioBase {
    scenario: Scenario.Elaborate;
    capabilities: ElaborateCapabilitiesType[];
}

interface RewriteScenario extends SupportedScenarioBase {
    scenario: Scenario.Rewrite;
    capabilities: RewriteCapabilitiesType[];
}

export type SupportedScenario = GeneralScenario | ElaborateScenario | RewriteScenario;

export interface CopilotCapable {
    capable: boolean;
}

export interface CopilotSettingsResponse {
    supportedScenarios: SupportedScenario[];
    copilotLicenses: LicenseType[];
    copilotCapable: CopilotCapable;
    isBetaUser: boolean;
}

export const ELABORATE_FILTER_SCENARIOS = ['New', 'Reply'] as const;
export type ElaborateFilterScenarios = typeof ELABORATE_FILTER_SCENARIOS[number];

export const ELABORATE_FILTER_TYPES = ['tone', 'verbosity'] as const;
export type ElaborateFilterTypes = typeof ELABORATE_FILTER_TYPES[number];

export type ElaborateSettingsServiceBlobFilters = {
    [key in ElaborateFilterScenarios]: ElaborateFilterSettings;
};

export type ElaborateFilterSettings = {
    [key in ElaborateFilterTypes]: string;
};

export interface ElaborateSettingsServiceBlob extends ElaborateSettingsServiceBlobFilters {
    TonePersonalization: 'seen' | 'unseen';
}
