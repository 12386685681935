import type { MailboxInfo } from 'owa-client-types';
import type { CopilotSettingsStore, ImplementedCopilotFeatures } from 'owa-copilot-settings-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { createStoreCheckForGenericEnablement } from './createStoreCheckForGenericEnablement';

const canUseBetaEndpoint = createStoreCheckForGenericEnablement<
    ImplementedCopilotFeatures,
    boolean
>('BetaEndpoint', canUseBetaEndpointInternal);

export default canUseBetaEndpoint;

function canUseBetaEndpointInternal(
    store: CopilotSettingsStore,
    _language: string,
    _scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo
): boolean {
    if (
        store.isEnabled &&
        store.isBetaUser &&
        isFeatureEnabled('mon-copilot-beta-endpoint', mailboxInfo)
    ) {
        return true;
    }

    return false;
}
