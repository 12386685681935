import type {
    ImplementedCopilotFeatures,
    CopilotSettingsStore,
    CopilotCapableAddinInfo,
} from 'owa-copilot-settings-store';
import { createStoreCheckForGenericEnablement } from './createStoreCheckForGenericEnablement';
import type { MailboxInfo } from 'owa-client-types';
import {
    LicenseType,
    copilotSettingsStore,
    getTenantDefaultAddin,
    lazySetAddinSettingsToOcs,
} from 'owa-copilot-settings-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getApplicationSettings } from 'owa-application-settings';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

const ADDIN_BY_SCENARIO_LOCAL_STORAGE_KEY = 'olk-copilot-last-used-addin';

const getAddinsAvailableForFeature = createStoreCheckForGenericEnablement<
    ImplementedCopilotFeatures,
    CopilotCapableAddinInfo[]
>('GetAvailableAddins', getAddinsAvailableForFeatureInternal);

export default getAddinsAvailableForFeature;

function getAddinsAvailableForFeatureInternal(
    store: CopilotSettingsStore,
    _language: string,
    scenario: ImplementedCopilotFeatures,
    mailboxInfo: MailboxInfo
): CopilotCapableAddinInfo[] {
    // In the future, we should check every addin we have to see if it's individually licensed, if needed.
    // But since we're under time pressure, we are assuming that all addins are sales addins.
    // Once we have a proper extensibility story this check will likely change.
    const hasSalesLicense =
        isSalesLicensePresent(store) ||
        getApplicationSettings('Copilot', mailboxInfo).enableGeneric1PAddinSupport;

    if (store.isEnabled && hasSalesLicense) {
        const addinsInfo = store.addinsInfo.get(scenario);
        if (!!addinsInfo && addinsInfo.length > 0) {
            return addinsInfo;
        }
    }
    return [];
}

function isSalesLicensePresent(store: CopilotSettingsStore) {
    return (
        store.licenses.indexOf(LicenseType.SalesCopilot) >= 0 ||
        isFeatureEnabled('mon-copilot-ext-debug-addins')
    );
}

export function getStringsForScenarioAndTitleId(
    scenario: ImplementedCopilotFeatures,
    titleId: string,
    mailboxInfo: MailboxInfo
) {
    const store = copilotSettingsStore(mailboxInfo);
    if (!!store && store.isEnabled && isSalesLicensePresent(store)) {
        const foundAddin = store.addinsInfo.get(scenario)?.find(addin => addin.titleId == titleId);
        return foundAddin
            ? {
                  scenarioStrings: foundAddin.scenarioStrings.get(scenario),
                  fallbackAddinName: foundAddin.fallbackAddinName,
                  iconUri: foundAddin.menuIcon,
              }
            : undefined;
    }
    return undefined;
}

export async function updateLastUsedAddin(
    scenario: ImplementedCopilotFeatures,
    titleId: string,
    mailboxInfo: MailboxInfo
) {
    const store = copilotSettingsStore(mailboxInfo);
    if (
        !!store &&
        store.isEnabled &&
        store.scenarioToAddinSettingsMap &&
        getApplicationSettings('Copilot', mailboxInfo).enableGeneric1PAddinSupport
    ) {
        const tenantDefaultAddin = getTenantDefaultAddin(scenario, mailboxInfo);
        if (tenantDefaultAddin) {
            store.scenarioToAddinSettingsMap[scenario] = {
                tenantDefault: tenantDefaultAddin,
                lastUsedAddin: titleId,
            };
        } else {
            store.scenarioToAddinSettingsMap[scenario] = {
                lastUsedAddin: titleId,
            };
        }
        const setAddinSettingsToOcs = await lazySetAddinSettingsToOcs.import();
        setAddinSettingsToOcs(mailboxInfo, store.scenarioToAddinSettingsMap);
        window.localStorage.removeItem(getLocalStorageKeyForMbxInfo(mailboxInfo)); //cleanup previously stored value in local storage
    }
}

function getLocalStorageKeyForMbxInfo(mailboxInfo: MailboxInfo) {
    return getIndexerValueForMailboxInfo(mailboxInfo) + '_' + ADDIN_BY_SCENARIO_LOCAL_STORAGE_KEY;
}
